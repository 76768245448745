import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      // {
      //   path: "/master-user-hp",
      //   name: "master-user-hp",
      //   component: () => import("./pages/master/UserHp.vue"),
      // },
      // {
      //   path: "/master-user-web",
      //   name: "master-user-web",
      //   component: () => import("./pages/master/UserWeb.vue"),
      // },
      {
        path: "/master-beat",
        name: "master-beat",
        component: () => import("./pages/master/Beat.vue"),
      },
      {
        path: "/master-call-plan",
        name: "master-call-plan",
        component: () => import("./pages/master/CallPlan.vue"),
      },
      {
        path: "/master-noo",
        name: "master-noo",
        component: () => import("./pages/master/Noo.vue"),
      },
      {
        path: "/master-salesman",
        name: "master-salesman",
        component: () => import("./pages/master/Salesman.vue"),
      },
      {
        path: "/master-reset-gps",
        name: "master-reset-gps",
        component: () => import("./pages/master/ResetGps.vue"),
      },
      {
        path: "/master-reset-startend",
        name: "master-reset-startend",
        component: () => import("./pages/master/ResetStartEnd.vue"),
      },
      {
        path: "/master-beat/:id/edit",
        name: "master-beat-edit",
        component: () => import("./components/master/beat/EditTemp.vue"),
      },
      {
        path: "/master-outlet",
        name: "master-outlet",
        component: () => import("./pages/master/Outlet.vue"),
      },
      {
        path: "/database-frekuensi-order-outlet",
        name: "database-frekuensi-order-outlet",
        component: () => import("./pages/database/FrekuensiOrderOutlet.vue"),
      },
      {
        path: "/report-visit",
        name: "report-visit",
        component: () => import("./pages/report/Visit.vue"),
      },
      {
        path: "/report-order",
        name: "report-order",
        component: () => import("./pages/report/Order.vue"),
      },
      {
        path: "/testing-master-user-web",
        name: "testing-master-user-web",
        component: () => import("./pages/testing/UserWebTest.vue"),
      },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !localStorage.getItem("authToken")) {
    next({ name: "login" });
  } else if (to.name === "login" && localStorage.getItem("authToken")) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
